<template>
  <div>
    <portal to="page-top-title">Campaign Labels</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'campaigns-labels-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/campaigns/labels" :url-query="{ with_count: ['campaigns'] }" data-prop="labels" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th class="text-center">Campaigns</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="label in labels" :key="`labels-tr-${label.id}`" :value="label">
          <td>{{ label.id }}</td>
          <td>{{ label.name }}</td>
          <td class="text-center">
            <badge title="Total">{{ label.campaigns_count }}</badge>
          </td>
          <td class="text-center">
            <badge-active :value="label.active"/>
          </td>
          <td class="text-right">{{ label.created_at }}</td>
          <td class="text-right">{{ label.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'campaigns-labels-edit', params: { id: label.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'campaigns-labels-single', params: { id: label.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row >
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Badge from '@/views/components/simple/Badge'

export default {
  name: 'CampaignsLabelsList',
  metaInfo: {
    title: 'Campaigns | Labels'
  },
  components: {
    BoxTable,
    Btn,
    BadgeActive,
    Badge
  },
  data () {
    return {
      labels: []
    }
  }
}
</script>
